module.exports = {
  hu: {
    about_us: "Rólunk",
    add_tag: "Kulcsszó hozzáadása",
    add_tag_helper: `Kulcsszavak hozzáadása előtt kérjük olvassa el <a class="custom" href="/hu/cimkezes-utmutato/" target="_blank">címkézési útmutatónkat!</a>`,
    add_tag_action: "Küldés",
    allow_cookies: "Engedélyezem",
    back: "Vissza",
    cancel: "Mégsem",
    city: "Város",
    city_and_location: "Város, helyszín",
    close: "Bezárás",
    copy_link_clipboard_failed: "A hivatkozás másolása nem sikerült.",
    copy_link_clipboard: "A hivatkozás a vágólapra került.",
    copy_link: "Hivatkozás másolása",
    copy_text_clipboard_failed: "A szöveg másolása nem sikerült.",
    copy_text_clipboard: "A szöveg a vágólapra került.",
    contact_us: "Kapcsolat",
    country: "Ország",
    darkmode: "Sötét mód",
    decline: "Elutasítom",
    description: "Leírás",
    details: "Fotó részletek",
    dialog_download: "Kérjük, hogy a letöltött fotó forrását minden esetben így adja meg: $donor",
    dialog_download_action: "Fotó letöltése",
    donate: "Támogatás",
    donor: "Fotó adományozó",
    donors: "Fotó adományozók",
    download: "Letöltés",
    email: "Email",
    forum: "Fórum",
    fullscreen: "Teljes képernyő",
    full_name: "Teljes név",
    go: "Mehet",
    keyword: "Kulcsszó",
    language: "Nyelv",
    latest: "Friss",
    lightmode: "Világos mód",
    lists: "Listák",
    list: "Lista",
    list_add_to: "Listához adás",
    list_add_photos: "Képek hozzáadása",
    list_new: "+ Új lista létrehozása",
    list_select: "Lista kiválasztása...",
    list_name: "Lista neve",
    list_new_name: "Új lista neve",
    list_create_header: "Új lista",
    list_create_success: "Sikeres létrehozás: ",
    list_create_error: "Nem sikerült lértehozni a listát, kérjük próbálja meg újra!",
    list_submit_error_name_missing: "Kérjük adja meg az új lista nevét.",
    list_add_success: "Hozzáadva a listához: ",
    list_add_error: "Nem sikerült a fotót a listához adni, kérjük próbálja újra!",
    list_signin_alert: "A listák használatához kérjük jelentkezzen be fiókjába.",
    list_added_to: "Listákhoz adva",
    list_description: "Leírás",
    list_description_label: "Leírás (opcionális, max. 140 karakter)",
    list_view: "Lista megtekintése",
    list_remove_from: "Törlés a listából",
    list_remove_from_success: "Törölve a listából: ",
    list_remove_from_error: "Nem sikerült törölni a fotót a listából, kérjük próbálja meg újra!",
    list_edit: "Szerkesztés",
    list_edit_header: "Lista szerkesztése",
    list_edit_success: "Sikeres módosítás",
    list_edit_error: "Nem sikerült elmenteni a módostásokat, kérjük próbálja újra!",
    list_delete: "Törlés",
    list_delete_header: "Lista törlése",
    list_delete_confirm_msg: "Biztos, hogy törli az alábbi listát és a képeit? (A művelet nem visszavonható):",
    list_delete_success: "Sikeres törlés",
    list_delete_error: "Nem sikerült törölni a listát, kérjük próbálja meg újra!",
    list_empty_header: "Nincsenek még listái",
    list_empty_text: "A listák segítségével elmentheti és rendszerezheti a Fortepan archívumának képeit.",
    list_photos_empty_header: "Ez a lista még üres",
    list_photos_empty_text: "A Fortepan archívumában böngészve adhat képeket a listához.",
    list_photo_removed: "Ez a kép el lett távolítva a Fortepan archívumából",
    list_edit_private: "Privát lista",
    list_edit_public_label: "A lista az url ismeretében bárki által megtekinthető illetve beágyazható más weboldalakon",
    list_edit_private_label: "A lista csak az ön számára érhető el",
    list_404: "A keresett lista nem publikus vagy nem létezik.",
    list_copy_link: "Lista linkjének másolása",
    list_embed: "Lista beágyazása",
    load_more: "Még több kép",
    location: "Helyszín",
    login: "Belépés",
    menu: "Menü",
    next: "Következő",
    notifications: "Értesítések",
    notifications_empty: "Jelenleg nincsenek új értesítések.",
    password_forgot: "Elfelejtett jelszó?",
    password_forgot_details: "Elfelejtett Jelszavának módosításához kérjük adja meg felhasználónevét vagy email címét.",
    password_forgot_error: "Ismeretlen felhaszálónév vagy email cím.\nKérjük ellenőrizze adatait és próbálja meg újra.",
    password_forgot_not_activated: "A felhasználói fiókot még nem aktiválták vagy letiltották.",
    password_forgot_success: "A jelszó módosításához szükséges hivatkozást email címére elküldtük.",
    password_reset: "Jelszó módosítása",
    password_reset_error: "A jelszó módosítása közben hiba történt, kérjük próbálja meg később.",
    password_reset_success: "A jelszó módosítása megtörtént.",
    password_new: "Új jelszó",
    password_save: "Jelszó mentése",
    password: "Jelszó",
    photographer: "Szerző",
    photo_results: "kép",
    photos: "Fotók",
    privacy_policy: "Adatvédelem",
    picture_id: "Képszám",
    place: "Helyszín",
    prev: "Előző",
    profile: "Profil",
    reset_password: "Új jelszó igénylése",
    save: "Mentés",
    search_more: "Adjon meg egy, vagy több keresőszót",
    search_phrase: "Keresőszó",
    search: "Keresés",
    settings: "Beállítások",
    share_email: "Megosztás emailben",
    share_facebook: "Megosztás Facebookon",
    share_twitter: "Megosztás Twitteren",
    share: "Megosztás",
    signin: "Belépés",
    signin_long: "Már rendelkezem fiókkal. Belépés",
    signout: "Kilépés",
    signup: "Regisztráció",
    signup_long: "Még nem rendelkezem fiókkal. Regisztráció",
    slideshow: "Diavetítés",
    tag: "Kulcsszó",
    tags: "Kulcsszavak",
    tags_signin_alert: "Új kulcsszó hozzáadásához kérjük, hogy előbb lépjen be.",
    tags_save_success: "A javasolt kulcsszavakat adminisztrátorunk rövidesen ellenőrzi.",
    tags_save_error: "A mentés közben hiba történt, kérjük próbálja meg később.",
    timeline: "Idővonal",
    user_profile: "Felhasználói profil",
    user_signin_error: "A belépés nem sikerült.\nKérjük ellenőrizze adatait és próbálja meg újra.",
    user_signin_success: "Sikeres belépés.",
    user_signup_email_invalid: "Helytelenül megadott email cím.",
    user_signup_email_missing: "Az email cím megadása kötelező.",
    user_signup_email_taken: "A megadott email címmel már regisztráltak.",
    user_signup_password_missing: "Jelszó megadása kötelező.",
    user_signup_success: "Sikeres regisztráció",
    user_signup_username_missing: "Felhasználónév megadása kötelező.",
    user_signup_username_taken: "A megadott felhasználónévvel már regisztráltak.",
    username: "Felhasználónév",
    username_or_email: "Felhasználónév vagy Email",
    weekly_fortepan: "Heti Fortepan blog",
    year: "Év",
    year_from: "Évtől",
    year_to: "Évig",
    age_restriction: {
      title: "Korhatáros tartalom",
      content: "A kép megtekintése a nyugalom megzavarására alkalmas, kiskorúak számára nem ajánlott!",
      reveal_label: "Elmúltam 18 éves, megnézem",
      cancel_label: "Nem múltam el 18 éves",
      remove_action_label: "Megtekintés",
    },
    projects: "Projektek",
    photo_uploads: "Korábbi feltöltések",
    tax1percent: {
      menu_label: "Adó 1%",
      header: "1% ADÓ - 100% FOTÓ",
      blurb: "Támogassa Ön és családja adója egy százalékával a Fortepant!",
      close_button: "Bezárás",
      more_button: "Részletek",
    },
    thumbnails: "Nézőképek",
    embed: {
      empty_list: "Ez a lista nem tartalmaz még képeket.",
      title_404: "Hmm... :/",
      message_404: "A keresett lista nem érhető el.",
      dialog: {
        title: "Lista beágyazása",
        ratio: "Méretarány",
        ratio_custom: "Egyedi",
        width: "Szélesség",
        height: "Magasság",
        full_width: "Teljes szélesség",
        embed_code: "Beágyazó kód",
        copy_embed_code: "Kód másolása",
        embed_code_copied: "A beágyazó kód a vágólapra került",
        embed_code_copy_failed: "A beágyazó kód a másolása nem sikerült",
        change_privacy: "Módosítás",
        upsell: {
          helper_text_lists: "A képet tartalmazó $p1listát be tudja ágyazni$p2 más weboldalakon is.",
          helper_text: "A képet $p1egy listához adva$p2 be tudja ágyazni más weboldalakon is.",
          docs_link: "Részletes útmutató&nbsp;→",
        },
      },
    },
    read_more: "Bővebben",
    select_option: "Válasszon...",
    advanced_search: {
      label: "Összetett keresés",
      empty: "Üres",
    },
  },
  en: {
    about_us: "About us",
    add_tag: "Add new tag",
    add_tag_action: "Submit",
    allow_cookies: "Allow cookies",
    back: "Back",
    cancel: "Cancel",
    city: "City",
    city_and_location: "City, location",
    close: "Close",
    copy_link_clipboard_failed: "Failed to copy link.",
    copy_link_clipboard: "Link has been copied to clipboard.",
    copy_link: "Copy Link",
    copy_text_clipboard_failed: "Failed to copy text.",
    copy_text_clipboard: "Text has been copied to clipboard.",
    contact_us: "Contact us",
    country: "Country",
    darkmode: "Dark mode",
    decline: "Decline",
    description: "Description",
    details: "Photo details",
    dialog_download: "Please, mark the source of the downloaded photo in all cases on the following way: $donor",
    dialog_download_action: "Download photo",
    donate: "Donate",
    donor: "Donor",
    donors: "Donors",
    download: "Download",
    email: "Email",
    forum: "Forum",
    fullscreen: "Fullscreen",
    full_name: "Full name",
    go: "Go",
    keyword: "Keyword",
    language: "Language",
    latest: "Recently added",
    lightmode: "Light mode",
    lists: "Lists",
    list: "List",
    list_add_to: "Add to list",
    list_add_photos: "Add photos",
    list_new: "+ Create new list",
    list_select: "Select list...",
    list_name: "List name",
    list_new_name: "List name",
    list_create_header: "New list",
    list_create_success: "Created successfully: ",
    list_create_error: "Oops, we couldn't create the new list, please try again!",
    list_submit_error_name_missing: "Please fill out the list name.",
    list_add_success: "Successfully added to the list: ",
    list_add_error: "Oops, we couldn't add the photo to the list, please try again!",
    list_signin_alert: "Please sign in to your account to use the lists feature.",
    list_added_to: "Added to",
    list_description: "Description",
    list_description_label: "Description (optional, max. 140 character)",
    list_view: "View list",
    list_remove_from: "Remove from list",
    list_remove_from_success: "Successfully removed from the list: ",
    list_remove_from_error: "Oops, we couldn't remove the photo from the list, please try again!",
    list_edit: "Edit",
    list_edit_header: "Edit list",
    list_edit_success: "Changes are saved",
    list_edit_error: "Oops, we couldn't save the changes, please try again!",
    list_delete: "Delete",
    list_delete_header: "Delete list",
    list_delete_confirm_msg: "You're about to delete the following list and its photos (this action cannot be undone):",
    list_delete_success: "The list is deleted",
    list_delete_error: "Oops, we couldn't delete the list, please try again!",
    list_empty_header: "No lists to show yet",
    list_empty_text: "You can use lists to save and organize photos of the Fortepan archive.",
    list_photos_empty_header: "No photos yet",
    list_photos_empty_text: "You can add photos to this list while browsing the Fortepan archive.",
    list_photo_removed: "This photo is not available on Fortepan anymore",
    list_edit_private: "Private list",
    list_edit_public_label: "Anyone with the url can view this list",
    list_edit_private_label: "Only you can view this list",
    list_404: "The list you're looking for is not public or doesn't exist.",
    list_copy_link: "Copy link to list",
    list_embed: "Embed list",
    load_more: "Load more",
    location: "Location",
    login: "Login",
    menu: "Menu",
    next: "Next",
    notifications: "Notifications",
    notifications_empty: "No new notications.",
    password_forgot: "Forgot password?",
    password_forgot_details: "Enter your username or email to reset your password.",
    password_forgot_error: "Oops, we couldn't reset your password. \nPlease check your details and try again.",
    password_forgot_not_activated: "The user has not been activated or is blocked.",
    password_forgot_success: "Password reset link sent to your email.",
    password_reset: "Reset password",
    password_reset_error: "An error occurred, please try again later.",
    password_reset_success: "Your new password was saved.",
    password_new: "New password",
    password_save: "Save password",
    password: "Password",
    photographer: "Photographer",
    photo_results: "photos",
    photos: "Photos",
    picture_id: "Photo ID",
    place: "Place",
    prev: "Previous",
    privacy_policy: "Privacy Policy",
    profile: "Profile",
    reset_password: "Reset password",
    save: "Save",
    search_more: "Add one or more keyword(s)",
    search_phrase: "Search phrase",
    search: "Search",
    settings: "Settings",
    share_email: "Share via Email",
    share_facebook: "Share on Facebook",
    share_twitter: "Share on Twitter",
    share: "Share",
    signin: "Sign in",
    signin_long: "Already have an account? Sign in",
    signout: "Sign out",
    signup: "Sign up",
    signup_long: "Don't have an account? Sign up",
    slideshow: "Slideshow",
    tag: "Tag",
    tags: "Tags",
    tags_save_success: "Thank you for your submission, it'll be reviewed soon.",
    tags_save_error: "Unexpected error while saving, please try again later.",
    tags_signin_alert: "Please sign in first before tagging this photo.",
    timeline: "Timeline",
    user_profile: "User Profile",
    user_signin_error: "Oops, we couldn’t sign you in.\nPlease check your details and try again.",
    user_signin_success: "Your sign-in was successful.",
    user_signup_email_invalid: "The email format is invalid.",
    user_signup_email_missing: "Valid email address is required.",
    user_signup_email_taken: "Email address is already taken.",
    user_signup_password_missing: "Password is required.",
    user_signup_success: "Your sign-up was successful.",
    user_signup_username_missing: "Username is required.",
    user_signup_username_taken: "Username is already taken.",
    username: "Username",
    username_or_email: "Username or Email",
    weekly_fortepan: "Weekly Fortepan blog",
    year: "Year",
    year_from: "Year from",
    year_to: "Year to",
    age_restriction: {
      title: "Age-restricted content",
      content: "This content may be offensive or disturbing, not appropriate for visitors under 18 years.",
      reveal_label: "I'm over 18, show content",
      cancel_label: "I'm not 18 yet",
      remove_action_label: "Show",
    },
    projects: "Projects",
    photo_uploads: "Previous uploads",
    thumbnails: "Show thumbnails",
    embed: {
      empty_list: "This list doesn't have photos yet.",
      title_404: "Oops... :/",
      message_404: "The requested list can't be displayed.",
      dialog: {
        title: "Embed list",
        ratio: "Size ratio",
        ratio_custom: "Custom",
        width: "Width",
        height: "Height",
        full_width: "Full width",
        embed_code: "Embed code",
        copy_embed_code: "Copy embed code",
        embed_code_copied: "The embed code has been copied to clipboard",
        embed_code_copy_failed: "Failed to copy embed code",
        change_privacy: "Change",
        upsell: {
          helper_text_lists: "You can also $p1embed this list$p2 on any website.",
          helper_text: "You can also $p1add this photo to a list$p2 and embed it on any website.",
          docs_link: "Learn&nbsp;how&nbsp;→",
        },
      },
    },
    read_more: "Read more",
    select_option: "Select...",
    advanced_search: {
      label: "Advanced search",
      empty: "Empty",
    },
  },
}
